import './style.css';
import React, { useEffect, useState } from "react";
import {isMobile} from 'react-device-detect';

const Light = ({mousePos, windowPos, color, zIndex, blend, blur, bHeight}) => {

    const [degree, setDegree] = useState(0);

    useEffect(async () => {
        console.log(`ismobile: ${isMobile}`)
        const xRel = (windowPos?.right * 0.1) - mousePos.X
        const calculatedDeg = Math.atan(xRel/mousePos.Y) * 180 / Math.PI
        setDegree(Math.min(Math.max(calculatedDeg, -60), 0))
    }, [mousePos])

    return(
        <div className='light' style=
        {{transform: `rotate(${degree}deg)`,
            WebkitTransform: `rotate(${degree}deg)`,
            borderBottomColor: color,
            zIndex: zIndex,
            mixBlendMode: blend,
            filter: `blur(${blur}px)`,
            borderBottomWidth: `${bHeight}vh`,
            transition: `${isMobile ? "transform 1s" : ""}`}}></div>
    )
}


export default Light
import './App.css';
import Light from './views/Light';
import Lamp from './views/Lamp';
import React, { useState } from "react";
import useResizeObserver from "@react-hook/resize-observer";

function useSize(target) {
  const [size, setSize] = React.useState();

  React.useLayoutEffect(() => {
    target && setSize(target.getBoundingClientRect());
  }, [target]);

  // Where the magic happens
  useResizeObserver(target, (entry) => setSize(entry.contentRect));
  return size;
}

function App() {
  const [mousePos, setMousePos] = useState({ X: 0, Y: 0});

  const handleMouseMove = (e) => {
    setMousePos({X: e.clientX, Y: e.clientY})
  }

  const [target, setTarget] = React.useState();
  const size = useSize(target);

  return (
    <div className="App" onMouseMove={handleMouseMove} ref={setTarget}>
      <Light mousePos={mousePos} windowPos={size} color='rgba(255, 255, 255, 0.822)' zIndex={3} blend='difference' blur={4} bHeight={190}/>
      <Light mousePos={mousePos} windowPos={size} color='rgb(0, 0, 0)' zIndex={-1} blend='normal' blur={6} bHeight={200}/>
      <Lamp mousePos={mousePos} windowPos={size}/>
      <header className="App-header">
        <h1>Onur Eren Arpacı</h1>
        <p>I am a junior computer engineering student at Koç University. I am interested in finance, and full stack web development. I aspire to be a competent and innovative software developer.</p>
      </header>
    </div>
  );
}

export default App;

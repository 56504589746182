import {ReactComponent as LampBody} from './silhouette-objet-body.svg';
import {ReactComponent as LampHead} from './silhouette-objet-head.svg';
import {isMobile} from 'react-device-detect';
import './style.css'

import React, { useEffect, useState } from "react";

const Lamp = ({mousePos, windowPos}) => {

    const [degree, setDegree] = useState(0);

    useEffect(async () => {
        const xRel = (windowPos?.right * 0.1) - mousePos.X
        const calculatedDeg = Math.atan(xRel/mousePos.Y) * 180 / Math.PI
        setDegree(Math.min(Math.max(calculatedDeg, -60), 0))
    }, [mousePos])

    return(
        <div className='wrapper'>
            <LampBody className='lampBody' viewBox="0 0 287 766"/>
            <LampHead className='lampHead' viewBox="0 0 217 235"  style={{transform: `rotate(${degree}deg)`,
            WebkitTransform: `rotate(${degree}deg)`,
            transition: `${isMobile ? "transform 1s" : ""}`}}/>
        </div>
    )
}


export default Lamp